body {
  margin: 0;
}

#portfolio {
  padding: 50px 0;
}

.work-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
}

.work {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.work img {
  width: 100%;
  height: 50vh !important;
  border-radius: 10px;
  display: block;
  transition: transform 0.5s;
}

.work-title {
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), #FF5733);
  color: #fff;
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  font-family: Poppins, sans-serif;
  transition: height 0.5s ease-in-out;
  overflow: hidden; 
}

.work-title h3 {
  font-weight: 500;
  margin: 0 12px 5px 12px;
  font-size: 20px;
  text-align: start;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.work-title span {
  font-weight: 500;
  font-size: 18px;
  margin: 0 12px 5px 12px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.work-title p {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 1px;
  margin: 0 12px 10px 12px;
}

.work:hover .work-title {
  height: 100%; 
}

.work:hover img {
  transform: scale(1.1);
}

.btn {
  display: block;
  margin: 50px auto;
  width: fit-content;
  border: 1px solid #ff004f;
  padding: 14px 50px;
  border-radius: 6px;
  text-decoration: none;
  color: #fff;
  transition: background 0.5s;
}

.btn:hover {
  background-color: #ff004f;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}



.pagination button {
  padding: 10px;
  min-width: 40px;
  border: none;
  border-radius: 5px;
  background-color: #f68f5c ;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background: linear-gradient(135deg, #FF716E 0%, #FF5733 100%);
  color: white;
}

.pagination button:disabled {
  background-color: #d6d6d6;
  color: #aaa;
  cursor: not-allowed;
}

.pagination button.active {
  background-color:  #FF5733;
  color: white;
  font-weight: bold;
}

.pagination button.active:hover {
  background-color: #f68f5c;
}