.footer-link {
  color: black;
  text-decoration: none;
  font-size: 18px;
  transition: transform 0.4s ease-in-out; 
}

.footer-link:hover {
  transform: translateX(10px); 
  color: #f68f5c; 
}
