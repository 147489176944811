.header {
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: top 0.3s, background-color 0.3s, box-shadow 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  height: 100px;
}

.nav-logo-parent {
  display: flex;
  align-items: center;
}

.navbarresponsivelogo {
  height: 50px;
  width: auto;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.links li {
  margin: 0 15px;
  position: relative;
}

.nav-links {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  padding: 10px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

/* Dropdown styles */
.nav-links-dropdown .ant-dropdown-trigger {
  display: inline-block;
}

.nav-links.active {
  color: #f68f5c !important;
}

.toggle_btn {
  display: none;
  cursor: pointer;
}

.dropdown_menu {
  display: none;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: "rgba(0, 0, 0, 0.8)";
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100px;
  z-index: 999;
}

.dropdown_menu.open {
  display: flex;
}

.dropdown_menu li {
  display: none;
}

@media (max-width: 1000px) {
  .links {
    display: none;
    height: 50vh;
  }
  .nav-links {
    color: #fff;
  }

  .toggle_btn {
    display: block;
    padding: 10px;
  }

  .dropdown_menu {
    display: none;
    font-size: 18px;
    letter-spacing: 1.8px;
    right: 0;
    justify-content: center;
  }

  .dropdown_menu.open {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dropdown_menu li {
    margin-top: 20px;
    right: 0;
    display: flex;
  }
}


/* Product Menu Dropdown */
.product-menu {
  /* background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 100px; */
  margin-left: -15px !important;
}

.product-menu .ant-menu-item {
  /* padding: 10px 16px; */
  /* font-size: 16px;
  font-weight: 500;
  color: #333; */
  transition: background-color 0.3s ease !important;
}

/* .product-menu .ant-menu-item:hover {
  background-color: #f0f0f0;
} */

.product-menu a {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;

}

.product-menu a:hover {
  color: #f68f5c !important
}
