.Headingandpsaragraph {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #fff;
  border-radius: 2px;
}

.letsTalkBtn {
  margin-top: 3px;
}

.homeCardText{
  /* padding: 20px 10px 15px 20px;
  flex:1; */
  border-radius: 5px;
  box-shadow: 0 0 70px 0 #00000021 !important;
  z-index: 1;
  background: #ffffff;
  transition: 0.8s;
  margin:3px 20px 15px 20px !important;
}

.homeCard {
  padding: 20px 10px 15px 20px;
  /* flex:1; */
  border-radius: 5px;
  box-shadow: 0 0 70px 0 #00000021 !important;
  z-index: 1;
  background: #ffffff;
  transition: 0.8s;
  margin:3px 20px 15px 20px !important;
}

.homeCardMedia {
  border-radius: 40px;
  padding: 18px 20px;
  background-color: #f68f5c;
  color:#ffffff;
  width: fit-content;
}

/* @media (min-width: 860px) {
  .homeCard {
    flex:1;
  }
} 


@media (min-width: 860px) and (max-width: 1100px) {
  .homeCard {
    margin:3px 6px 15px 6px !important;
  }
  
}  */


