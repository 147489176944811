.letstalkmainbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
   
  .responsive-button {
    /* padding: 12px 15px; */
    font-weight: 600;
    cursor: pointer;
    border: none;
    /* background-color: #f68f5c; */
    background: linear-gradient(135deg, #FF716E 0%, #FF5733 100%);
    border-radius: 20px;
    color: white;
    transition: background-color 0.9s ease !important;
    font-size: 12px;
    letter-spacing: 0.9px;
    font-family:Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    transition: all 0.5s ease-in-out;
    padding: 20px;
  }
  
  .responsive-button:hover {
    /* background-color: #fd7e14 !important; */
 background: linear-gradient(135deg, #FF716E 0%, #F68f5c 100%) !important;
    color: white !important;

  }
  
  .form-container {
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .wide-input {
    width: 100%;
    
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #f68f5c;
    font-size: large;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }
  
  button[type="submit"]:hover {
    background-color: #dd8051;
  }
  
   @media (max-width: 576px) {
    .form-container {
      padding: 10px;
    }

  }
  
  @media (min-width: 600px) {
    .form-container {
      padding: 20px;
    }
  } 




  