
.WorkProcess-card:hover {
  margin-top: -5px;
  transition: "all 0.10s ease 5s";
}



/* style for SingleBlogPost component header  */
#single-blogpost-header {
  height: 1rem !important; /* Adjust the height as needed */

}


@media screen and (max-width: 768px) {
  
  .WorkProcess-card {
    justify-content: center !important;
    align-items: center !important;
  }
}